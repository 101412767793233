<template>
  <v-row>
    <v-col cols="12">
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Cloning',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('tasks.cloning.meta.title')} — %s`
    }
  }
}
</script>

<style scoped></style>
